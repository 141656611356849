@import "../../../styles/constants.scss";


.barcode-scanner-static {

    &__tips-overlay {
        background: $white;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        transform: scale(1) translateY(-50%);
        z-index: 25;
        max-width: calc(100% - 60px);
        padding: 30px;
        transform-origin: top;

        &-close {
            position: absolute;
            top: 20px;
            right: 20px;
            font-size: 14px;
            cursor: pointer;
        }

        &-title {
            font-family: $font-sans-serif;
            font-size: 15px;
            color: $black;
            letter-spacing: 0.75px;
            text-transform: uppercase;
            margin: 28px 0;
            text-align: center;
        }
    }

    &__tips {

        &-scan {

            .button-simple {
                width: 100%;
                background: $black;
                position: absolute;
                bottom: 0;
                left: 0;
                font-family: $font-sans-serif;
                font-size: 14px;
                color: $white;
                letter-spacing: 1.5px;
            }

        }

        &-images {
            display: flex;

            .skip-icon, .add-icon {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: 0 auto;
                width: 32px;
                height: 32px;
                line-height: 32px;
                font-size: 14px;
                color: $white;
                border: 1px solid $white;
                border-radius: 50px;
                transform: translateY(50%);
                background: #9F0000;
                text-align: center;
            }

            .add-icon {
                background: #276446;
            }

            img {
                display: block;
                width: 100%;
            }

            &-tip1, &-tip2 {
                flex: auto;
                margin-right: 16px;
                position: relative;
            }

            &-tip2 {
                margin: 0;
            }
        }

        &-list {
            padding: 0;
            margin: 44px 0 44px 17px;

            li {
                font-family: $font-sans-serif-book;
                font-size: 14px;
                color: $gray-dark;
                letter-spacing: 0.5px;
                line-height: 23px;
                text-align: left;
            }
        }
    }
}

@media (max-width: $breakpoint-xs) {
    .barcode-scanner-static {
        &__tips-overlay {
            transform: scale(0.85) translateY(-50%);
            &-title {
                font-size: 12px;
            }
        }
        &__tips {
            &-images {
                .skip-icon, .add-icon {
                    width: 26px;
                    height: 26px;
                    line-height: 26px;
                    font-size: 12px;
                }
            }
            &-list {
                &-header p {
                    font-size: 14px;
                }
                li {
                    font-size: 12px;
                    line-height: 17px;
                }
            }
        }
    }
}
