@import '../../styles/constants.scss';

.info-message {
    background: $white;

    &__inner {
        font-family: $font-sans-serif-book;
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 22px;

        &--error {
            @include message-error();

            a {
                color: $error;
            }
        }

        &--success {
            @include message-success();
        }

        &--warning {
            @include message-warning();
        }

        &--information {
            @include message-information();
        }

        &--shipping {
            @include message-shipping();
        }

        &--final-sale {
            @include message-final-sale();
        }

        &--align-center {
            text-align: center;
        }

        &--align-left {
            text-align: left;
        }

        &--align-right {
            text-align: right;
        }
    }
}
