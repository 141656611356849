@import "../../styles/constants.scss";

@keyframes moveUpDown {
    0% { bottom: 0; }
    50% { bottom: 40px; }
    100% { bottom: 0; }
}

.barcode-scanner-static {
    text-align: center;
    position: relative;

    .skip-icon {
      line-height: 40px;
      img {
        width: 20px;
        height: 20px;
        display: inline-block;
      }
    }

    path {
      fill: #ffffff;
    }

    &__options {
        position: fixed;
        bottom: 0;
        padding-bottom: 10%;
        width: 100%;
        text-align: center;
    }

    &__tip {
        text-align: center;
        width: 100%;
        position: absolute;
        bottom: 60px;
        left: 0;
        font-family: $font-sans-serif;
        font-size: 14px;
        color: $white;
        letter-spacing: .4px;
        line-height: 22px;
        padding: 0 5vh;
    }

    .icon {
        width: 29px;
        height: 29px;
        display: inline-block;
        border: 1px solid $white;
        vertical-align: middle;
        border-radius: 50px;
        line-height: 28px;
    }

    &__input {
        position: absolute;
        top: -10;
        width: 0.1px;
        height: 0.1px;
        overflow: hidden;
    }

    &__label {
        margin: 0 0 70px;
        position: relative;
        display: block;

        &-img {
            display: block;
            width: 100%;
        }

        &-icon {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 60px;
            height: 60px;
            border: 1px solid $white;
            line-height: 63px;
            font-size: 25px;
            background: $black;
            border-radius: 100px;
            transform: translateY(50%);
            padding: 9px;
        }
    }

    &__tips {
        font-family: $font-sans-serif-book;
        color: $gray-dark;
        font-size: 14px;
        margin: 26px 0;
        letter-spacing: .5px;
    }

    &__search {

        .icon {
            border: 1px solid #33526C;
            width: 29px;
            height: 29px;
            display: inline-block;
            vertical-align: middle;
            border-radius: 50px;
            line-height: 28px;
        }

        &-text {
            /* Search with the prod: */
            font-family: $font-sans-serif-book;
            font-size: 14px;
            color: #33526C;
            letter-spacing: 0.4px;
            line-height: 22px;
            margin-left: 10px;
            padding-bottom: 3px;
            border-bottom: 1px solid #33526C;
        }
    }

    &__cannot-decode {
        font-family: $font-sans-serif;

        .barcode-scanner-static {

            &__tips-scan {
                margin: 30px 20px 20px 20px;
                padding-bottom: 26px;
                position: relative;
            }

            &__tips-list {
                &-header {
                    margin: 30px 40px 0;
                    text-align: left;
                }
                margin: 10px 54px 44px 54px;
            }
        }
    }

    &__crop-overlay {
        background-color: $black;
        position: relative;
        z-index: 19;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
        max-height: calc(100vh - 52px);

        &-content {
            z-index: 20;
            overflow: auto;

            .image-cropper {
                min-height: calc(100vh - 120px);

                &__resizer {
                    background: rgba(255,255,255,0.40);
                }
            }
        }

        &-message {
            color: white;
            font-family: $font-sans-serif;
            padding: 10px 0;
            font-size: 12px;
        }

        &-buttons {
            position: absolute;
            top: 20px;
            right: 6px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            text-align: right;
            color: $white;
            width: 170px;
            font-family: $font-sans-serif;

            .icon {
                text-align: center;
                flex: 0 auto;
                font-size: 15px;
                background: $black;
                width: 33px;
                height: 33px;
                line-height: 31px;
                padding: 5px;
            }
        }

        &-button {
            width: 100%;
            display: block;
            text-align: center;

            span {
                font-size: 11px;
            }

            &--help {

                .icon {
                    font-family: $font-sans-serif;
                    font-size: 22px;
                    font-style: normal;
                }
            }
        }

        &-crop-button {
            width: 100%;
            display: block;
            text-align: center;
            position: absolute;
            bottom: 20px;

            .button-simple {
                position: relative !important;
                background: $black;
                font-family: $font-sans-serif;
                font-size: 14px;
                color: $white;
                letter-spacing: 1.5px;
            }
        }
    }

    &__upload {
        position: absolute;
        width: 100%;
        height: 100vh;
        z-index: 10;
        background: $white;

        &-inner {
            position: absolute;
            top: 35%;
            left: 0;
            right: 0;
            margin: 0 auto;
            text-align: center;
            transform: translateY(-50%);

            .icon {
                position: relative;
                bottom: 0;
                font-size: 45px;
                color: $gray-dark;
                animation: moveUpDown 1.5s infinite;
                transform: translate3d(0,0,0);
            }

            &-copy {
                font-family: $font-sans-serif-book;
                font-size: 14px;
                color: $gray-dark;
                letter-spacing: 0.5px;
                line-height: 23px;
                margin: 25px;
            }
        }
    }
}

@media (max-width: $breakpoint-xs) {
    .barcode-scanner-static {
        &__crop-overlay {
            max-height: calc(100vh - 100px);
        }
        &__tip {
            font-size: 12px;
            line-height: 18px;
        }
        &__search {

            .icon {
                width: 23px;
                height: 23px;
                line-height: 23px;
                font-size: 12px;
                margin-top: 3px;
            }

            &-text {
                font-size: 12px;
            }
        }
        &__cannot-decode {
            .info-message__inner {
                font-size: 12px;
                line-height: 18px;
            }
        }
    }
}
