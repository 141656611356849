// Base colors
$white: #ffffff;
$white-transparent: rgba($white, 0.1);
$black: #050708;
$gray: #565458;
$gray-dark: #363636;
$gray-light: #d9d9d9;
$gray-lightest: #f8f8f8;
$green: #276446;
$green-transparent: rgba($green, 0.1);
$orange: #a03013;
$orange-transparent: rgba($orange, 0.1);
$red: #b30008;
$red-transparent: rgba($red, 0.1);
$blue: #1259a4;
$blue-transparent: rgba($blue, 0.1);

// Brand colors
$gold: #866347;
$teal: #006167;
$gold-transparent: rgba($gold, 0.05);
$teal-transparent: rgba(0, 97, 103, 0.1);
$cardinal-red: #af1a31;
$cardinal-red-dark: #851023;
$registry: #9aaeb7;
$registry-dark: #33526c;
$navy: #023a51;
$midnight: #002230;
$stone: #47525e;

// Seasonal colors (Summer 2017)
$silver: #cbcbcb;
$stormy: #5b5a5b;
$stormy-sea: #2b3445;
$blue-jeans: #6a7892;
$moss: #739388;
$khaki-green: #c4d2c4;
$beige: #dfd3bf;
$tiger: #d6a05f;
$red-clay: #c9745c;
$blush: #e5a8a3;
$coral: #f65044;
$salmon: #df3321;
$rosy-orange: #c71301;

// Brand - Baby + Kids
$banana: #ffd587;
$blush: #fac6b9;
$burnt-orange: #b34e24;
$sky: #b2e1e9;

$business-light: #c58987;
$business-dark: #923e33;

// Social Colors
$facebook: #3b5998;
$googleplus: #dd4b39;
$twitter: #0084b4;
$pinterest: #c92228;
$instagram: #3f279b;
$youtube: #e52d27;

// UX colors
$body-background: $white;
$sale: $cardinal-red;
$promo: $cardinal-red;
$error: $red;
$warning: $orange;
$success: $green;
$information: $blue;
$highlight: $teal-transparent;
$favorite: $cardinal-red;
$disabled: $gray;

// Text Colors
$heading-color: $black;
$text-color: $gray-dark;
$link-color: $teal;
$link-hover: $gold;
$link-title: $gray-dark;

// Buttons
$submit-button-active: $black;
$submit-button-hover: $teal;
$submit-button-focus: #0A6166;
$submit-button-disabled: $gray-lightest;

// Other
$divider: #d8d8d8;

$font-sans-serif-light: "GT-Eesti 2r", Arial, sans-serif;
$font-sans-serif: "GT-Eesti 3r", Arial, sans-serif;
$font-sans-serif-regular: "GT-Eesti 4r", Arial, sans-serif;
$font-sans-serif-medium: "GT-Eesti 5r", Arial, sans-serif;
$font-sans-serif-bold: "GT-Eesti 6r", Arial, sans-serif;

$font-sans-serif-secondary: Arial, sans-serif;

$font-serif: "Sanomat 4r", Georgia, serif;
$font-serif-medium: "Sanomat 5r", Georgia, serif;
$font-serif-bold: "Sanomat 6r", Georgia, serif;

$font-serif-secondary: Georgia, serif;

$font-condensed: "GT-Eesti 3r", Arial, sans-serif;
$font-sans-serif-book: "GT-Eesti 2r", Arial, sans-serif;

$font-x-small: 11.062px;
$font-small: 12.444px;
$font-normal: 14px;
$font-medium: 15.75px;
$font-large: 17.719px;
$font-x-large: 19.934px;
$font-x-large--2: 22.425px;
$font-x-large--3: 25.228px;

$breakpoint-xs: '374px';
$breakpoint-sm: '415px';
$breakpoint-md: '736px';
$breakpoint-lg: '1024px';
$breakpoint-xl: '1025px';

$global-spacer--half: 5px;
$global-spacer: 10px;
$global-spacer--three-halves: 15px;
$global-spacer--2: 20px;
$global-spacer--3: 30px;
$global-spacer--4: 40px;
$global-spacer--5: 50px;
$global-spacer--6: 60px;
$global-spacer--7: 70px;
$global-spacer--8: 80px;
$global-spacer--9: 90px;
$global-spacer--10: 100px;

@mixin messaging-error {
    padding: 10px 15px;
    background: rgba(179, 0, 8, 0.1);
    margin: 0;
    color: #b30008;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.7em;
    letter-spacing: 0.5px;
    font-family: $font-sans-serif;
    max-width: 100%;
}

@mixin messaging-warning {
    background: rgba(160, 48, 19, 0.1);
    margin: 0;
    color: #a03013;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.7em;
    letter-spacing: 0.5px;
    font-family: $font-sans-serif;
    max-width: 100%;
}

@mixin messaging-backorder {
    color: #b30008;
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.7em;
    letter-spacing: 0.5px;
    font-family: $font-sans-serif-light;
    max-width: 100%;
    margin: $global-spacer--half 0;
}

@mixin heading-1($text-transform) {
    margin: 0 0 $global-spacer--three-halves;
    font-family: $font-sans-serif;
    font-weight: 400;
    font-size: $font-x-large--2;
    line-height: 38px;
    letter-spacing: 0.8px;
    color: $heading-color;
    text-transform: $text-transform;
}

@mixin heading-2($text-transform) {
    margin: 0 0 $global-spacer;
    font-family: $font-serif;
    font-weight: 400;
    font-size: $font-x-large;
    line-height: 35px;
    letter-spacing: 0.5px;
    color: $gray;
    text-transform: $text-transform;
}

@mixin heading-3($text-transform) {
    margin: 0 0 $global-spacer;
    font-family: $font-sans-serif;
    font-weight: 300;
    font-size: $font-large;
    line-height: 32px;
    letter-spacing: 0.8px;
    color: $text-color;
    text-transform: $text-transform;
}

@mixin heading-4($text-transform) {
    margin: 0 0 $global-spacer;
    font-family: $font-sans-serif-medium;
    font-weight: 500;
    font-size: $font-large;
    font-style: italic;
    line-height: 28px;
    letter-spacing: 1px;
    color: $heading-color;
    text-transform: $text-transform;
}

@mixin heading-5($text-transform) {
    margin: 0 0 $global-spacer;
    font-family: $font-serif;
    font-weight: 400;
    font-style: italic;
    font-size: $font-medium;
    line-height: 30px;
    letter-spacing: 1px;
    color: $text-color;
    text-transform: $text-transform;
}

@mixin heading-6($text-transform) {
    margin: 0 0 $global-spacer;
    font-family: $font-sans-serif-medium;
    font-weight: 500;
    font-size: $font-medium;
    line-height: 23px;
    letter-spacing: 0.8px;
    color: $heading-color;
    text-transform: $text-transform;
}

@mixin base-p() {
    font-family: $font-sans-serif-light;
    font-weight: 300;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.5px;
    color: $text-color;
    margin-bottom: $global-spacer--three-halves;
}

@mixin base-link($padding,$border) {
    padding-bottom: $padding;
    color: $link-color;
    text-decoration: none;
    border-bottom: $border;
    cursor: pointer;

    &:hover,
    &:focus {
		color: $link-hover;
		border-color: $link-hover;
    }
}

@mixin title-link($padding,$border) {
    padding-bottom: $padding;
    color: $link-title;
    text-decoration: none;
    cursor: pointer;
    border-bottom: $border;

    &:hover,
    &:focus {
        color: $link-hover;
        border-color: $link-hover;
    }
}

@mixin base-caption() {
    font-family: $font-sans-serif;
    font-weight: 300;
    font-size: $font-x-small;
    line-height: 17px;
    letter-spacing: 0.5px;
    margin-bottom: $global-spacer--three-halves;
}

@mixin fine-print() {
    font-family: $font-sans-serif-light;
    font-style: italic;
    font-weight: 300;
    font-size: $font-x-small;
    line-height: 17px;
    letter-spacing: 0.5px;
    margin-bottom: $global-spacer--three-halves;
}

@mixin triangle-shape-down() {
    content: " ";
    width: 0;
    height: 0;
    border-width: 4px 0 4px 6px;
    border-style: solid;
    border-color: transparent transparent transparent $gray-dark;
    border-image: initial;
    position: absolute;
    right: 13px;
    top: 48%;
    transform: translateY(-50%) rotate(90deg);
}

@mixin triangle-shape() {
    content: " ";
    width: 0;
    height: 0;
    border-width: 4px 0 4px 6px;
    border-style: solid;
    border-color: transparent transparent transparent $registry-dark;
    border-image: initial;
    position: absolute;
    right: -13px;
    top: 48%;
    transform: translateY(-50%);
}

@mixin message-error() {
    padding: 14px $global-spacer;
    color: $error;
    background: rgba($error, 0.1);
}

@mixin message-warning() {
    padding: 14px $global-spacer;
    color: $warning;
    background: rgba($warning, 0.1);
}

@mixin message-success() {
    padding: 14px $global-spacer;
    color: $success;
    background: rgba($success, 0.1);
}

@mixin message-information() {
    padding: 14px $global-spacer;
    color: $information;
    background: rgba($information, 0.1);
}

@mixin message-shipping() {
    padding: 14px $global-spacer;
    font-style: italic;
    border-top: 1px solid $black;
}

@mixin message-final-sale() {
    padding: 14px $global-spacer;
    color: $link-color;
    background: rgba($link-color, 0.05);
}

@mixin icon-3d {
    display: block;
    width: 24px;
    height: 24px;
    background: #737175;
    color: $white;
    border-radius: 50px;
    text-align: center;
    line-height: 24px;
    font-family: $font-sans-serif;
    font-weight: 700;
    font-size: 10px;
    letter-spacing: 0;
    border: 1px solid $white;
}

@mixin button-tertiary($color) {
    width: 100%;
    min-height: 40px;
    padding: $global-spacer--half;
    font-family: $font-sans-serif-regular;
    font-size: $font-normal;
    letter-spacing: 1px;
    line-height: 23px;
    color: $color;
    border: 1px solid $color;
    background: $body-background;
    border-radius: 2px;
    cursor: pointer;

    &:hover,
    &:focus {
        color: $white;
        background: $color;
    }
}

@mixin plus-icon($color, $hover-color) {
    &:before {
        content: '+';
        position: absolute;
        right: 0;
        top: 50%;
        color: $color;
        border: 0;
        width: 12px;
        height: 12px;
        font-size: 12px;
        left: auto;
        text-align: center;
        line-height: 12px;
        transform: translateY(-50%) rotate(0deg);
        transition: all .3s ease;
    }

    &:after {
        content: ' ';
        position: absolute;
        top: 50%;
        right: 0;
        width: 12px;
        height: 12px;
        display: block;
        border: 1px solid $color;
        border-radius: 20px;
        transform: translateY(-50%);
        transition: all .3s ease;
    }

    &:hover,
    &:focus {

        &:before, &:after {
            color: $hover-color;
            border-color: $hover-color;
        }
    }
}

//Replaced
//$a: #E71818; $salmon
//$b: #F17474; $red-clay
//$c: #444444; $gray-dark
//$d: #444; $gray-dark
//$e: #555555; $gray
//$f: #555; $gray
//$h: #3e3e3e; $gray-dark
//$i: #cacaca; $gray-light
//$k: #676767; $gray
//$o: #ebebeb; $gray-lightest
//$p: #c4c4c4; $sliver
//$q: #006167; $teal
//$r: #cccccc; $silver
//$s: #ccc; $silver
//$u: #696969; $stormy
//$v: #2574f2; $blue
//$w: #e1513e; $coral
//$y: #3e5e9e; $facebook
//$z: #cfcfcf; $gray-light
//$a1: #525252; $gray
//$d1: #CCC9C9; $gray-light
//$e1: #050708; $black
//$f1: #34526B; $registry-dark
//$g1: #ECF3F9; $gray-light
//$h1: #33526C $registry-dark
//$i1: #737175 $stormy
//$j1: #4f4f4f; $gray
//$k1: #343434; gray-dark
//$l1: #f8f8f8; gray-lightest
//$m1: #32526d; $registry-dark
//$n1: #276446; $green

// Can't find a similar color
// #8c8c8c; = $gray;
// #a1a1a1; = $gray-light;
// #74bfbc; = $teal;
// #222222; = $black;
// #818993; = What is this used for right now?
// #aa8f6c; = $gold;
// #808080; = $gray;
// #14b5ef; = What is this used for right now?
// #959595; = $gray;
// #acacac; = $gray-light;
