@import "../../styles/constants.scss";

$rectangle-width: 50vw;
$rectangle-height: 56vw;
$tip-width: 180px;

.barcode-scanner {

    &__holder {
        position: relative;
        overflow: hidden;

        &__scanning-tip {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 10;
            text-align: center;
            padding: 13px 0 24px 0;

            .icon {
                border: 1px solid #fff;
                width: 29px;
                height: 29px;
                display: inline-block;
                vertical-align: middle;
                border-radius: 50px;
                line-height: 28px;
                font-style: normal;
                font-size: 18px;
                color: #fff;
            }

            &-text {
                font-size: 14px;
                color: #fff;
                letter-spacing: 0.4px;
                line-height: 22px;
                margin-left: 10px;
                padding-bottom: 3px;
                border-bottom: 1px solid #fff;
            }
        }

        &__tip {
            text-align: center;
            width: 100vw;
            position: absolute;
            transform: translateY(200%);
            bottom: 0;
            left: 0;
            right: 0;
            margin-left: -25vw;
            font-size: 14px;
            color: #fff;
            letter-spacing: .4px;
            line-height: 22px;
        }
    }

    video, canvas {
        max-width: 100%;
        position: absolute;
        top: 0;
        transform: scale(1.5);
    }

    video::-webkit-media-controls-container {
        opacity: 0 !important;
    }

    &__container {
        position: absolute;
    }

    &__position-hinter {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        &-rectangle {
            width: $rectangle-width;
            height: $rectangle-height;
            top: 50%;
            position: absolute;
            margin: 0 auto;
            box-shadow: 0 0 0 9999em rgba(0, 0, 0, .3);
            left: 50%;
            transform: translate(-50%,-50%);
            backface-visibility: hidden;
            .barscan {
                position: absolute;
                left: 0;
                top: 0;
                width: 10px;
                height: 100%;
                background-color: rgba( 0, 97, 103, 0.1);
                z-index: 1;
                transform: translateX(48vw);
                animation: move 3.7s cubic-bezier(0.4, 0.5, 0.66, 0.94);
                animation-iteration-count: infinite;
            }
            &:before,  &:after, em:after, em:before {
                border-color: #000;
                content: "";
                position: absolute;
                width: 19px;
                height: 16px;
                border-style: solid;
                border-width: 0px;
            }
            &:before {
                left: 0;
                top: 0;
                border-left-width: 1px;
                border-top-width: 1px;
            }
            &:after {
                right: 0;
                top: 0;
                border-right-width: 1px;
                border-top-width: 1px;
            }
            em:before {
                left: 0;
                bottom: 0;
                border-left-width: 1px;
                border-bottom-width: 1px;
            }
            em:after {
                right: 0;
                bottom: 0;
                border-right-width: 1px;
                border-bottom-width: 1px;
            }

            &--success {
                background: rgba(255,255,255,0.85);
            }

            &__message {
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                margin: 0 auto;
                transform: translateY(-50%);
                text-align: center;
                padding: 30px;

                .add-icon {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    font-size: 19px;
                    color: #fff;
                    border: 1px solid #fff;
                    border-radius: 50px;
                    text-align: center;
                    background: #276446;
                    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
                    display: inline-block;
                }

                p {
                    font-family: 'Georgia';
                    font-size: 18px;
                    font-style: italic;
                    color: #000;
                    letter-spacing: 0.5px;
                    line-height: 24px;
                }
            }
        }

        &-tip {
            background: rgba(255, 255, 255, .8);
            color: #000;
            border-radius: 2px;
            width: $tip-width;
            padding: 15px;
            font-size: 10px;
            text-align: center;
            margin: 0 auto;
            top: 45%;
            left: calc(50% - #{$tip-width / 2});
            transform: translateY(#{$rectangle-height / 2 + 30});
            opacity: 0;
            position: absolute;
            box-shadow: rgba(0, 0, 0, .4) 0 2px 2px;
            transition: opacity .5s, transform .5s;

            &--visible {
                transform: translateY(#{$rectangle-height / 2 + 10});
                opacity: 1;
            }

            &:before {
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                content: " ";
                position: absolute;
                top: -5px;
                left: calc(50% - 5px);

                border-bottom: 5px solid rgba(255, 255, 255, .8);
            }

            &-close {
                position: absolute;
                right: -5px;
                top: -5px;
                font-size: 14px;
                cursor: pointer;
                padding: 10px;
            }
        }
    }

    &__switch-camera {
        position: absolute;
        z-index: 2;
        top: 30px;
        right: 30px;
        font-size: 24px;
    }
}

@keyframes move {
    0%, 100% { transform: translateX(48vw); }
    50% { transform: translateX(0%); }
  }

@media (max-width: $breakpoint-xs) {
    .barcode-scanner {
        &__holder {
            &__tip {
                font-size: 12px;
                line-height: 18px;
            }
            &__scanning-tip {

                .icon {
                    width: 23px;
                    height: 23px;
                    line-height: 23px;
                    font-size: 12px;
                }

                &-text {
                    font-size: 12px;
                }
            }
        }
        &__switch-camera {
            font-size: 19px;
        }
    }
}
